
import { useRouter, useRoute } from 'vue-router';
import Base from "../layout/Base.vue"
import { useCityRelTown } from "@/network/chinaArea";
import {
  useSelectItemList
} from "@/network/lookUp.ts";
import { useFindHeritage } from "@/network/heritageUnit.ts";
import { defineComponent, toRef, toRefs, ref, reactive, computed, watch, onMounted, inject } from "vue";
import roles from "@/types/roles.ts";
export default defineComponent({
  components: { Base },
  props: {
    id: { type: Number },
    isUnit: { type: Number }
  },
  setup(props, { emit }) {
    const route = useRoute();
    const router = useRouter();
    const refForm = ref(null);
    const user: any = inject("user");
    const redirectUrl = ref((route.query.redirect || '/HeritageUnit/create').toString());
    const rules = {


      heritage_Code: [
        { required: true, message: "请输入不可移动文物编号！", trigger: "blur" },
      ],
      heritage_Name: [
        { required: true, message: "请输入不可移动文物名称！", trigger: "blur" },
      ],
      heritageLevel_Code: [
        { required: true, message: "请输入不可移动文物级别！", trigger: "change" },
      ],
      heritageBatch_ID: [
        { required: true, message: "请输入不可移动文物批次！", trigger: "change" },
      ],
      heritageClass_ID: [
        { required: true, message: "请输入不可移动文物分类！", trigger: "change" },
      ],
      // heritageAge_ID: [
      //   { required: true, message: "请输入不可移动文物年代！", trigger: "change" },
      // ],
      city_ID: [
        { required: true, message: "请输入不可移动文物地址！", trigger: "change" },
      ],
      county_ID: [
        { required: true, message: "请输入不可移动文物地址！", trigger: "change" },
      ],
      heritage_Address: [
        { required: true, message: "请输入不可移动文物详细地址！", trigger: "blur" },
      ],
    };
    const form = reactive({
      id: 0,
      heritage_Code: null,
      heritage_Name: null,
      heritageLevel_Code: null,
      heritageBatch_ID: null,
      heritageClass_ID: null,
      heritageAge_ID: null,
      city_ID: null,
      county_ID: null,
      heritage_Address: null,
      summary: null,
      heritageAge: null,
      isUnit: true
    });

    const { id, isUnit } = toRefs(props);
    const [isLoading, result, find, baseSave] = useFindHeritage();
    const dicHeritageLevel = useSelectItemList("HeritageLevel");
    const dicHeritageBatch = useSelectItemList("HeritageBatch");
    const dicHeritageClass = useSelectItemList("HeritageClass");
    const dicHeritageAge = useSelectItemList("HeritageAge");


    const [dicCity, dicTown] = useCityRelTown(
      toRef(form, "city_ID"),
      toRef(form, "county_ID")
    );

    const doSave = () => {
      refForm.value.validate((valid) => {
        if (valid) {
          // const req = Object.assign({}, form);
          // req.heritageAge_ID = parseInt(req.heritageAge_ID);
          // req.heritageBatch_ID = parseInt(req.heritageBatch_ID);
          // req.heritageClass_ID = parseInt(req.heritageClass_ID);
          if (form.heritageBatch_ID == null) form.heritageBatch_ID = 3935;
          
          if (form.heritageLevel_Code == "4") {
            form.isUnit = false;
          }
          else {
            form.isUnit = true;
          }
          baseSave(form).then(p => {
            router.push(redirectUrl.value);
          });
        }
      });
    }

    onMounted(() => {
      if (id.value) {
        find(id.value).then(p => {
          Object.assign(form, p);
        });
      }
    });

    return {
      redirectUrl,
      refForm,
      doSave,
      isLoading,
      user,
      roles,
      rules,
      form,
      dicHeritageLevel,
      dicHeritageBatch,
      dicHeritageClass,
      dicHeritageAge,
      dicCity,
      dicTown
    };
  },
});
